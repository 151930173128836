export default [
  {
    code: 'ADVENTIST',
    display: 'Adventist',
  },
  {
    code: 'AFRICAN_RELIGIONS',
    display: 'African Religions',
  },
  {
    code: 'AFRO_CARIBBEAN_RELIGIONS',
    display: 'Afro-Caribbean Religions',
  },
  {
    code: 'AGNOSTICISM',
    display: 'Agnosticism',
  },
  {
    code: 'ANGLICAN',
    display: 'Anglican',
  },
  {
    code: 'ANIMISM',
    display: 'Animism',
  },
  {
    code: 'ATHEISM',
    display: 'Atheism',
  },
  {
    code: 'BABI_&_BAHAI_FAITHS',
    display: "Babi & Baha'I faiths",
  },
  {
    code: 'BAPTIST',
    display: 'Baptist',
  },
  {
    code: 'BON',
    display: 'Bon',
  },
  {
    code: 'CAO_DAI',
    display: 'Cao Dai',
  },
  {
    code: 'CELTICISM',
    display: 'Celticism',
  },
  {
    code: 'CHRISTIAN_(NON-CATHOLIC,_NON-SPECIFIC)',
    display: 'Christian (non-Catholic, non-specific)',
  },
  {
    code: 'CONFUCIANISM',
    display: 'Confucianism',
  },
  {
    code: 'CYBERCULTURE_RELIGIONS',
    display: 'Cyberculture Religions',
  },
  {
    code: 'DIVINATION',
    display: 'Divination',
  },
  {
    code: 'FOURTH_WAY',
    display: 'Fourth Way',
  },
  {
    code: 'FREE_DAISM',
    display: 'Free Daism',
  },
  {
    code: 'GNOSIS',
    display: 'Gnosis',
  },
  {
    code: 'HINDUISM',
    display: 'Hinduism',
  },
  {
    code: 'HUMANISM',
    display: 'Humanism',
  },
  {
    code: 'INDEPENDENT',
    display: 'Independent',
  },
  {
    code: 'ISLAM',
    display: 'Islam',
  },
  {
    code: 'JAINISM',
    display: 'Jainism',
  },
  {
    code: 'JEHOVAHS_WITNESSES',
    display: "Jehovah's Witnesses",
  },
  {
    code: 'JUDAISM',
    display: 'Judaism',
  },
  {
    code: 'LATTER_DAY_SAINTS',
    display: 'Latter Day Saints',
  },
  {
    code: 'LUTHERAN',
    display: 'Lutheran',
  },
  {
    code: 'MAHAYANA',
    display: 'Mahayana',
  },
  {
    code: 'MEDITATION',
    display: 'Meditation',
  },
  {
    code: 'MESSIANIC_JUDAISM',
    display: 'Messianic Judaism',
  },
  {
    code: 'MITRAISM',
    display: 'Mitraism',
  },
  {
    code: 'NEW_AGE',
    display: 'New Age',
  },
  {
    code: 'NON-ROMAN_CATHOLIC',
    display: 'non-Roman Catholic',
  },
  {
    code: 'OCCULT',
    display: 'Occult',
  },
  {
    code: 'ORTHODOX',
    display: 'Orthodox',
  },
  {
    code: 'PAGANISM',
    display: 'Paganism',
  },
  {
    code: 'PENTECOSTAL',
    display: 'Pentecostal',
  },
  {
    code: 'PROCESS,_THE',
    display: 'Process, The',
  },
  {
    code: 'REFORMED/PRESBYTERIAN',
    display: 'Reformed/Presbyterian',
  },
  {
    code: 'ROMAN_CATHOLIC_CHURCH',
    display: 'Roman Catholic Church',
  },
  {
    code: 'SATANISM',
    display: 'Satanism',
  },
  {
    code: 'SCIENTOLOGY',
    display: 'Scientology',
  },
  {
    code: 'SHAMANISM',
    display: 'Shamanism',
  },
  {
    code: 'SHIITE_(ISLAM)',
    display: 'Shiite (Islam)',
  },
  {
    code: 'SHINTO',
    display: 'Shinto',
  },
  {
    code: 'SIKISM',
    display: 'Sikism',
  },
  {
    code: 'SPIRITUALISM',
    display: 'Spiritualism',
  },
  {
    code: 'SUNNI_(ISLAM)',
    display: 'Sunni (Islam)',
  },
  {
    code: 'TAOISM',
    display: 'Taoism',
  },
  {
    code: 'THERAVADA',
    display: 'Theravada',
  },
  {
    code: 'UNITARIAN-UNIVERSALISM',
    display: 'Unitarian-Universalism',
  },
  {
    code: 'UNIVERSAL_LIFE_CHURCH',
    display: 'Universal Life Church',
  },
  {
    code: 'VAJRAYANA_(TIBETAN)',
    display: 'Vajrayana (Tibetan)',
  },
  {
    code: 'VEDA',
    display: 'Veda',
  },
  {
    code: 'VOODOO',
    display: 'Voodoo',
  },
  {
    code: 'WICCA',
    display: 'Wicca',
  },
  {
    code: 'YAOHUSHUA',
    display: 'Yaohushua',
  },
  {
    code: 'ZEN_BUDDHISM',
    display: 'Zen Buddhism',
  },
  {
    code: 'ZOROASTRIANISM',
    display: 'Zoroastrianism',
  },
  {
    code: 'ASSEMBLY_OF_GOD',
    display: 'Assembly of God',
  },
  {
    code: 'BRETHREN',
    display: 'Brethren',
  },
  {
    code: 'CHRISTIAN_SCIENTIST',
    display: 'Christian Scientist',
  },
  {
    code: 'CHURCH_OF_CHRIST',
    display: 'Church of Christ',
  },
  {
    code: 'CHURCH_OF_GOD',
    display: 'Church of God',
  },
  {
    code: 'CONGREGATIONAL',
    display: 'Congregational',
  },
  {
    code: 'DISCIPLES_OF_CHRIST',
    display: 'Disciples of Christ',
  },
  {
    code: 'EASTERN_ORTHODOX',
    display: 'Eastern Orthodox',
  },
  {
    code: 'EPISCOPALIAN',
    display: 'Episcopalian',
  },
  {
    code: 'EVANGELICAL_COVENANT',
    display: 'Evangelical Covenant',
  },
  {
    code: 'FRIENDS',
    display: 'Friends',
  },
  {
    code: 'FULL_GOSPEL',
    display: 'Full Gospel',
  },
  {
    code: 'METHODIST',
    display: 'Methodist',
  },
  {
    code: 'NATIVE_AMERICAN',
    display: 'Native American',
  },
  {
    code: 'NAZARENE',
    display: 'Nazarene',
  },
  {
    code: 'PRESBYTERIAN',
    display: 'Presbyterian',
  },
  {
    code: 'PROTESTANT',
    display: 'Protestant',
  },
  {
    code: 'PROTESTANT,_NO_DENOMINATION',
    display: 'Protestant, No Denomination',
  },
  {
    code: 'REFORMED',
    display: 'Reformed',
  },
  {
    code: 'SALVATION_ARMY',
    display: 'Salvation Army',
  },
  {
    code: 'UNITARIAN_UNIVERSALIST',
    display: 'Unitarian Universalist',
  },
  {
    code: 'UNITED_CHURCH_OF_CHRIST',
    display: 'United Church of Christ',
  },
]
