export const SET_CHECKOUT = 'Set checkout'
export const DELETE_CHECKOUT = 'Delete checkout'
export const UPDATE_CHECKOUT = 'Update checkout'
export const ADD_CHECKOUT_DATA = 'Add checkout data'
export const SET_CHECKOUT_DATA = 'Set checkout data'
export const SET_EXISTING_PATIENT = 'Set existing patient'
export const CLEAR_CART = 'Clear cart'
export const ADD_CART_ITEMS = 'ADD_CART_ITEMS'
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM'
export const SET_ACTION = 'SET_ACTION'
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM'
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'
export const SET_PAYMENT_RESULT = 'SET_PAYMENT_RESULT'