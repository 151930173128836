export const SET_CLIENTS = 'Set clients'
export const DELETE_CLIENT = 'Delete clients'
export const UPDATE_CLIENT = 'Update clients'
export const UPDATE_FORM = 'Update form'
export const RESET_FORM = 'Reset form'
export const SET_FORM = 'Set form'
export const SET_CURRENT_CLIENT = 'Set current client'
export const SET_CLIENT_ACCOUNT = 'Set client account'
export const SET_CURRENT_UPDATE = 'Set current update'
export const SET_BILLS = 'Set bills'
export const SET_CLIENT_POLICIES = 'Set client policies'
export const SET_CLAIMS = 'Set client claims'
export const SET_CLIENTS_COUNT = 'Set clients counts'