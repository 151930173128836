export default [
  {
    code: 'AA',
    display: 'Afar',
  },
  {
    code: 'AB',
    display: 'Abkhazian',
  },
  {
    code: 'AF',
    display: 'Afrikaans',
  },
  {
    code: 'AK',
    display: 'Akan',
  },
  {
    code: 'AM',
    display: 'Amharic',
  },
  {
    code: 'AR',
    display: 'Arabic',
  },
  {
    code: 'AN',
    display: 'Aragonese',
  },
  {
    code: 'AS',
    display: 'Assamese',
  },
  {
    code: 'AV',
    display: 'Avaric',
  },
  {
    code: 'AE',
    display: 'Avestan',
  },
  {
    code: 'AY',
    display: 'Aymara',
  },
  {
    code: 'AZ',
    display: 'Azerbaijani',
  },
  {
    code: 'BA',
    display: 'Bashkir',
  },
  {
    code: 'BM',
    display: 'Bambara',
  },
  {
    code: 'BE',
    display: 'Belarusian',
  },
  {
    code: 'BN',
    display: 'Bengali',
  },
  {
    code: 'BI',
    display: 'Bislama',
  },
  {
    code: 'BO',
    display: 'Tibetan',
  },
  {
    code: 'BS',
    display: 'Bosnian',
  },
  {
    code: 'BR',
    display: 'Breton',
  },
  {
    code: 'BG',
    display: 'Bulgarian',
  },
  {
    code: 'CA',
    display: 'Catalan',
  },
  {
    code: 'CS',
    display: 'Czech',
  },
  {
    code: 'CH',
    display: 'Chamorro',
  },
  {
    code: 'CE',
    display: 'Chechen',
  },
  {
    code: 'CU',
    display: 'Church Slavic',
  },
  {
    code: 'CV',
    display: 'Chuvash',
  },
  {
    code: 'KW',
    display: 'Cornish',
  },
  {
    code: 'CO',
    display: 'Corsican',
  },
  {
    code: 'CR',
    display: 'Cree',
  },
  {
    code: 'CY',
    display: 'Welsh',
  },
  {
    code: 'DA',
    display: 'Danish',
  },
  {
    code: 'DE',
    display: 'German',
  },
  {
    code: 'DV',
    display: 'Dhivehi',
  },
  {
    code: 'DZ',
    display: 'Dzongkha',
  },
  {
    code: 'EL',
    display: 'Modern Greek (1453-)',
  },
  {
    code: 'EN',
    display: 'English',
  },
  {
    code: 'EO',
    display: 'Esperanto',
  },
  {
    code: 'ET',
    display: 'Estonian',
  },
  {
    code: 'EU',
    display: 'Basque',
  },
  {
    code: 'EE',
    display: 'Ewe',
  },
  {
    code: 'FO',
    display: 'Faroese',
  },
  {
    code: 'FA',
    display: 'Persian',
  },
  {
    code: 'FJ',
    display: 'Fijian',
  },
  {
    code: 'FI',
    display: 'Finnish',
  },
  {
    code: 'FR',
    display: 'French',
  },
  {
    code: 'FY',
    display: 'Western Frisian',
  },
  {
    code: 'FF',
    display: 'Fulah',
  },
  {
    code: 'GD',
    display: 'Scottish Gaelic',
  },
  {
    code: 'GA',
    display: 'Irish',
  },
  {
    code: 'GL',
    display: 'Galician',
  },
  {
    code: 'GV',
    display: 'Manx',
  },
  {
    code: 'GN',
    display: 'Guarani',
  },
  {
    code: 'GU',
    display: 'Gujarati',
  },
  {
    code: 'HT',
    display: 'Haitian',
  },
  {
    code: 'HA',
    display: 'Hausa',
  },
  {
    code: 'HE',
    display: 'Hebrew',
  },
  {
    code: 'HZ',
    display: 'Herero',
  },
  {
    code: 'HI',
    display: 'Hindi',
  },
  {
    code: 'HO',
    display: 'Hiri Motu',
  },
  {
    code: 'HR',
    display: 'Croatian',
  },
  {
    code: 'HU',
    display: 'Hungarian',
  },
  {
    code: 'HY',
    display: 'Armenian',
  },
  {
    code: 'IG',
    display: 'Igbo',
  },
  {
    code: 'IO',
    display: 'Ido',
  },
  {
    code: 'II',
    display: 'Sichuan Yi',
  },
  {
    code: 'IU',
    display: 'Inuktitut',
  },
  {
    code: 'IE',
    display: 'Interlingue',
  },
  {
    code: 'IA',
    display: 'Interlingua (International Auxiliary Language Association)',
  },
  {
    code: 'ID',
    display: 'Indonesian',
  },
  {
    code: 'IK',
    display: 'Inupiaq',
  },
  {
    code: 'IS',
    display: 'Icelandic',
  },
  {
    code: 'IT',
    display: 'Italian',
  },
  {
    code: 'JV',
    display: 'Javanese',
  },
  {
    code: 'JA',
    display: 'Japanese',
  },
  {
    code: 'KL',
    display: 'Kalaallisut',
  },
  {
    code: 'KN',
    display: 'Kannada',
  },
  {
    code: 'KS',
    display: 'Kashmiri',
  },
  {
    code: 'KA',
    display: 'Georgian',
  },
  {
    code: 'KR',
    display: 'Kanuri',
  },
  {
    code: 'KK',
    display: 'Kazakh',
  },
  {
    code: 'KM',
    display: 'Central Khmer',
  },
  {
    code: 'KI',
    display: 'Kikuyu',
  },
  {
    code: 'RW',
    display: 'Kinyarwanda',
  },
  {
    code: 'KY',
    display: 'Kirghiz',
  },
  {
    code: 'KV',
    display: 'Komi',
  },
  {
    code: 'KG',
    display: 'Kongo',
  },
  {
    code: 'KO',
    display: 'Korean',
  },
  {
    code: 'KJ',
    display: 'Kuanyama',
  },
  {
    code: 'KU',
    display: 'Kurdish',
  },
  {
    code: 'LO',
    display: 'Lao',
  },
  {
    code: 'LA',
    display: 'Latin',
  },
  {
    code: 'LV',
    display: 'Latvian',
  },
  {
    code: 'LI',
    display: 'Limburgan',
  },
  {
    code: 'LN',
    display: 'Lingala',
  },
  {
    code: 'LT',
    display: 'Lithuanian',
  },
  {
    code: 'LB',
    display: 'Luxembourgish',
  },
  {
    code: 'LU',
    display: 'Luba-Katanga',
  },
  {
    code: 'LG',
    display: 'Ganda',
  },
  {
    code: 'MH',
    display: 'Marshallese',
  },
  {
    code: 'ML',
    display: 'Malayalam',
  },
  {
    code: 'MR',
    display: 'Marathi',
  },
  {
    code: 'MK',
    display: 'Macedonian',
  },
  {
    code: 'MG',
    display: 'Malagasy',
  },
  {
    code: 'MT',
    display: 'Maltese',
  },
  {
    code: 'MN',
    display: 'Mongolian',
  },
  {
    code: 'MI',
    display: 'Maori',
  },
  {
    code: 'MS',
    display: 'Malay (macrolanguage)',
  },
  {
    code: 'MY',
    display: 'Burmese',
  },
  {
    code: 'NA',
    display: 'Nauru',
  },
  {
    code: 'NV',
    display: 'Navajo',
  },
  {
    code: 'NR',
    display: 'South Ndebele',
  },
  {
    code: 'ND',
    display: 'North Ndebele',
  },
  {
    code: 'NG',
    display: 'Ndonga',
  },
  {
    code: 'NE',
    display: 'Nepali (macrolanguage)',
  },
  {
    code: 'NL',
    display: 'Dutch',
  },
  {
    code: 'NN',
    display: 'Norwegian Nynorsk',
  },
  {
    code: 'NB',
    display: 'Norwegian Bokmål',
  },
  {
    code: 'NO',
    display: 'Norwegian',
  },
  {
    code: 'NY',
    display: 'Nyanja',
  },
  {
    code: 'OC',
    display: 'Occitan (post 1500)',
  },
  {
    code: 'OJ',
    display: 'Ojibwa',
  },
  {
    code: 'OR',
    display: 'Oriya (macrolanguage)',
  },
  {
    code: 'OM',
    display: 'Oromo',
  },
  {
    code: 'OS',
    display: 'Ossetian',
  },
  {
    code: 'PA',
    display: 'Panjabi',
  },
  {
    code: 'PI',
    display: 'Pali',
  },
  {
    code: 'PL',
    display: 'Polish',
  },
  {
    code: 'PT',
    display: 'Portuguese',
  },
  {
    code: 'PS',
    display: 'Pushto',
  },
  {
    code: 'QU',
    display: 'Quechua',
  },
  {
    code: 'RM',
    display: 'Romansh',
  },
  {
    code: 'RO',
    display: 'Romanian',
  },
  {
    code: 'RN',
    display: 'Rundi',
  },
  {
    code: 'RU',
    display: 'Russian',
  },
  {
    code: 'SG',
    display: 'Sango',
  },
  {
    code: 'SA',
    display: 'Sanskrit',
  },
  {
    code: 'SI',
    display: 'Sinhala',
  },
  {
    code: 'SK',
    display: 'Slovak',
  },
  {
    code: 'SL',
    display: 'Slovenian',
  },
  {
    code: 'SE',
    display: 'Northern Sami',
  },
  {
    code: 'SM',
    display: 'Samoan',
  },
  {
    code: 'SN',
    display: 'Shona',
  },
  {
    code: 'SD',
    display: 'Sindhi',
  },
  {
    code: 'SO',
    display: 'Somali',
  },
  {
    code: 'ST',
    display: 'Southern Sotho',
  },
  {
    code: 'ES',
    display: 'Spanish',
  },
  {
    code: 'SQ',
    display: 'Albanian',
  },
  {
    code: 'SC',
    display: 'Sardinian',
  },
  {
    code: 'SR',
    display: 'Serbian',
  },
  {
    code: 'SS',
    display: 'Swati',
  },
  {
    code: 'SU',
    display: 'Sundanese',
  },
  {
    code: 'SW',
    display: 'Swahili (macrolanguage)',
  },
  {
    code: 'SV',
    display: 'Swedish',
  },
  {
    code: 'TY',
    display: 'Tahitian',
  },
  {
    code: 'TA',
    display: 'Tamil',
  },
  {
    code: 'TT',
    display: 'Tatar',
  },
  {
    code: 'TE',
    display: 'Telugu',
  },
  {
    code: 'TG',
    display: 'Tajik',
  },
  {
    code: 'TL',
    display: 'Tagalog',
  },
  {
    code: 'TH',
    display: 'Thai',
  },
  {
    code: 'TI',
    display: 'Tigrinya',
  },
  {
    code: 'TO',
    display: 'Tonga (Tonga Islands)',
  },
  {
    code: 'TN',
    display: 'Tswana',
  },
  {
    code: 'TS',
    display: 'Tsonga',
  },
  {
    code: 'TK',
    display: 'Turkmen',
  },
  {
    code: 'TR',
    display: 'Turkish',
  },
  {
    code: 'TW',
    display: 'Twi',
  },
  {
    code: 'UG',
    display: 'Uighur',
  },
  {
    code: 'UK',
    display: 'Ukrainian',
  },
  {
    code: 'UR',
    display: 'Urdu',
  },
  {
    code: 'UZ',
    display: 'Uzbek',
  },
  {
    code: 'VE',
    display: 'Venda',
  },
  {
    code: 'VI',
    display: 'Vietnamese',
  },
  {
    code: 'VO',
    display: 'Volapük',
  },
  {
    code: 'WA',
    display: 'Walloon',
  },
  {
    code: 'WO',
    display: 'Wolof',
  },
  {
    code: 'XH',
    display: 'Xhosa',
  },
  {
    code: 'YI',
    display: 'Yiddish',
  },
  {
    code: 'YO',
    display: 'Yoruba',
  },
  {
    code: 'ZA',
    display: 'Zhuang',
  },
  {
    code: 'ZH',
    display: 'Chinese',
  },
  {
    code: 'ZU',
    display: 'Zulu',
  },
  {
    code: 'BH',
    display: 'Bihari languages',
  },
]
