export default () => ({
  clients: [],
  clientsCount: 0,
  bills: [],
  claims: [],
  client: {
    company: {},
  },
  clientAccount: {},
  clientPolicies: [],
  // form: {},
  form: {'company_name':'Stanbic','email':'test@stanbic.com','phone_number':'+233302815789','tin_number':'TESTTIN01','address':'Stanbic Heights\n215 South Liberation Link\nAirport City, Accra\nGhana','admin_first_name':'CALEB','admin_last_name':'TETTEH','admin_email':'calebkpabitey@gmail.com','admin_phoneno':'+233553009106'},
})
