export default [
  {
    code: 'Adult_mental_illness',
    display: 'Adult mental illness',
  },
  {
    code: 'Anesthetics',
    display: 'Anesthetics',
  },
  {
    code: 'Audiological_medicine',
    display: 'Audiological medicine',
  },
  {
    code: 'Blood_banking_and_transfusion_medicine',
    display: 'Blood banking and transfusion medicine',
  },
  {
    code: 'Burns_care',
    display: 'Burns care',
  },
  {
    code: 'Cardiology',
    display: 'Cardiology',
  },
  {
    code: 'Clinical_cytogenetics_and_molecular_genetics',
    display: 'Clinical cytogenetics and molecular genetics',
  },
  {
    code: 'Clinical_genetics',
    display: 'Clinical genetics',
  },
  {
    code: 'Clinical_hematology',
    display: 'Clinical hematology',
  },
  {
    code: 'Clinical_immunology',
    display: 'Clinical immunology',
  },
  {
    code: 'Clinical_microbiology',
    display: 'Clinical microbiology',
  },
  {
    code: 'Clinical_neuro-physiology',
    display: 'Clinical neuro-physiology',
  },
  {
    code: 'Clinical_oncology',
    display: 'Clinical oncology',
  },
  {
    code: 'Clinical_pharmacology',
    display: 'Clinical pharmacology',
  },
  {
    code: 'Clinical_physiology',
    display: 'Clinical physiology',
  },
  {
    code: 'Community_medicine',
    display: 'Community medicine',
  },
  {
    code: 'Critical_care_medicine',
    display: 'Critical care medicine',
  },
  {
    code: 'Dental_medicine_specialties',
    display: 'Dental medicine specialties',
  },
  {
    code: 'Dental-General_dental_practice',
    display: 'Dental-General dental practice',
  },
  {
    code: 'Dermatology',
    display: 'Dermatology',
  },
  {
    code: 'Diabetic_medicine',
    display: 'Diabetic medicine',
  },
  {
    code: 'Dive_medicine',
    display: 'Dive medicine',
  },
  {
    code: 'Endocrinology',
    display: 'Endocrinology',
  },
  {
    code: 'Family_practice',
    display: 'Family practice',
  },
  {
    code: 'Gastroenterology',
    display: 'Gastroenterology',
  },
  {
    code: 'General_medical_practice',
    display: 'General medical practice',
  },
  {
    code: 'General_medicine',
    display: 'General medicine',
  },
  {
    code: 'General_pathology',
    display: 'General pathology',
  },
  {
    code: 'General_practice',
    display: 'General practice',
  },
  {
    code: 'Genito-urinary_medicine',
    display: 'Genito-urinary medicine',
  },
  {
    code: 'Geriatric_medicine',
    display: 'Geriatric medicine',
  },
  {
    code: 'Gynecological_oncology',
    display: 'Gynecological oncology',
  },
  {
    code: 'Gynecology',
    display: 'Gynecology',
  },
  {
    code: 'Hematopathology',
    display: 'Hematopathology',
  },
  {
    code: 'Hepatology',
    display: 'Hepatology',
  },
  {
    code: 'Histopathology',
    display: 'Histopathology',
  },
  {
    code: 'Immunopathology',
    display: 'Immunopathology',
  },
  {
    code: 'Infectious_diseases',
    display: 'Infectious diseases',
  },
  {
    code: 'Internal_medicine',
    display: 'Internal medicine',
  },
  {
    code: 'Learning_disability',
    display: 'Learning disability',
  },
  {
    code: 'Medical_oncology',
    display: 'Medical oncology',
  },
  {
    code: 'Medical_ophthalmology',
    display: 'Medical ophthalmology',
  },
  {
    code: 'Military_medicine',
    display: 'Military medicine',
  },
  {
    code: 'Nephrology',
    display: 'Nephrology',
  },
  {
    code: 'Neurology',
    display: 'Neurology',
  },
  {
    code: 'Neuropathology',
    display: 'Neuropathology',
  },
  {
    code: 'Non_clinical_staff',
    display: 'Non-clinical staff',
  },
  {
    code: 'Nuclear_medicine',
    display: 'Nuclear medicine',
  },
  {
    code: 'Obstetrics',
    display: 'Obstetrics',
  },
  {
    code: 'Obstetrics_and_gynecology',
    display: 'Obstetrics and gynecology',
  },
  {
    code: 'Occupational_medicine',
    display: 'Occupational medicine',
  },
  {
    code: 'Ophthalmic_surgery',
    display: 'Ophthalmic surgery',
  },
  {
    code: 'Ophthalmology',
    display: 'Ophthalmology',
  },
  {
    code: 'Osteopathic_manipulative_medicine',
    display: 'Osteopathic manipulative medicine',
  },
  {
    code: 'Otolaryngology',
    display: 'Otolaryngology',
  },
  {
    code: 'Pain_management',
    display: 'Pain management',
  },
  {
    code: 'Palliative_medicine',
    display: 'Palliative medicine',
  },
  {
    code: 'Pediatric_(Child_and_adolescent)_psychiatry',
    display: 'Pediatric (Child and adolescent) psychiatry',
  },
  {
    code: 'Pediatric_cardiology',
    display: 'Pediatric cardiology',
  },
  {
    code: 'Pediatric_dentistry',
    display: 'Pediatric dentistry',
  },
  {
    code: 'Pediatric_endocrinology',
    display: 'Pediatric endocrinology',
  },
  {
    code: 'Pediatric_gastroenterology',
    display: 'Pediatric gastroenterology',
  },
  {
    code: 'Pediatric_genetics',
    display: 'Pediatric genetics',
  },
  {
    code: 'Pediatric_hematology',
    display: 'Pediatric hematology',
  },
  {
    code: 'Pediatric_immunology',
    display: 'Pediatric immunology',
  },
  {
    code: 'Pediatric_infectious_diseases',
    display: 'Pediatric infectious diseases',
  },
  {
    code: 'Pediatric_nephrology',
    display: 'Pediatric nephrology',
  },
  {
    code: 'Pediatric_oncology',
    display: 'Pediatric oncology',
  },
  {
    code: 'Pediatric_ophthalmology',
    display: 'Pediatric ophthalmology',
  },
  {
    code: 'Pediatric_pulmonology',
    display: 'Pediatric pulmonology',
  },
  {
    code: 'Pediatric_rheumatology',
    display: 'Pediatric rheumatology',
  },
  {
    code: 'Pediatric_surgery',
    display: 'Pediatric surgery',
  },
  {
    code: 'Pediatric_surgery-bone_marrow_transplantation',
    display: 'Pediatric surgery-bone marrow transplantation',
  },
  {
    code: 'Preventive_medicine',
    display: 'Preventive medicine',
  },
  {
    code: 'Psychiatry',
    display: 'Psychiatry',
  },
  {
    code: 'Psychotherapy',
    display: 'Psychotherapy',
  },
  {
    code: 'Public_health_medicine',
    display: 'Public health medicine',
  },
  {
    code: 'Pulmonary_medicine',
    display: 'Pulmonary medicine',
  },
  {
    code: 'Radiation_oncology',
    display: 'Radiation oncology',
  },
  {
    code: 'Radiology',
    display: 'Radiology',
  },
  {
    code: 'Radiology-Interventional_radiology',
    display: 'Radiology-Interventional radiology',
  },
  {
    code: 'Rehabilitation',
    display: 'Rehabilitation',
  },
  {
    code: 'Respite_care',
    display: 'Respite care',
  },
  {
    code: 'Rheumatology',
    display: 'Rheumatology',
  },
  {
    code: 'Sleep_studies',
    display: 'Sleep studies',
  },
  {
    code: 'Surgery-Bone_and_marrow_transplantation',
    display: 'Surgery-Bone and marrow transplantation',
  },
  {
    code: 'Surgery-Breast_surgery',
    display: 'Surgery-Breast surgery',
  },
  {
    code: 'Surgery-Cardiac_surgery',
    display: 'Surgery-Cardiac surgery',
  },
  {
    code: 'Surgery-Cardiothoracic_transplantation',
    display: 'Surgery-Cardiothoracic transplantation',
  },
  {
    code: 'Surgery-Colorectal_surgery',
    display: 'Surgery-Colorectal surgery',
  },
  {
    code: 'Surgery-Dental-Endodontics',
    display: 'Surgery-Dental-Endodontics',
  },
  {
    code: 'Surgery-Dental-Oral_and_maxillofacial_surgery',
    display: 'Surgery-Dental-Oral and maxillofacial surgery',
  },
  {
    code: 'Surgery-Dental-Oral_surgery',
    display: 'Surgery-Dental-Oral surgery',
  },
  {
    code: 'Surgery-Dental-Orthodontics',
    display: 'Surgery-Dental-Orthodontics',
  },
  {
    code: 'Surgery-Dental-Periodontal_surgery',
    display: 'Surgery-Dental-Periodontal surgery',
  },
  {
    code: 'Surgery-Dental-Prosthetic_dentistry_(Prosthodontics)',
    display: 'Surgery-Dental-Prosthetic dentistry (Prosthodontics)',
  },
  {
    code: 'Surgery-Dental-surgical-Prosthodontics',
    display: 'Surgery-Dental-surgical-Prosthodontics',
  },
  {
    code: 'Surgery-Dentistry-Restorative_dentistry',
    display: 'Surgery-Dentistry-Restorative dentistry',
  },
  {
    code: 'Surgery-Dentistry--surgical',
    display: 'Surgery-Dentistry--surgical',
  },
  {
    code: 'Surgery-Dentistry-surgical-Orthodontics',
    display: 'Surgery-Dentistry-surgical-Orthodontics',
  },
  {
    code: 'Surgery-Dermatologic_surgery',
    display: 'Surgery-Dermatologic surgery',
  },
  {
    code: 'Surgery-Ear,_nose_and_throat_surgery',
    display: 'Surgery Ear',
  },
  {
    code: 'Surgery-Ear, nose and throat surgery',
    display: ' Nose And Throat Surgery',
  },
  {
    code: 'Surgery-general',
    display: 'Surgery-general',
  },
  {
    code: 'Surgery-Hepatobiliary_and_pancreatic_surgery',
    display: 'Surgery-Hepatobiliary and pancreatic surgery',
  },
  {
    code: 'Surgery-Neurosurgery',
    display: 'Surgery-Neurosurgery',
  },
  {
    code: 'Surgery-Plastic_surgery',
    display: 'Surgery-Plastic surgery',
  },
  {
    code: 'Surgery-Transplantation_surgery',
    display: 'Surgery-Transplantation surgery',
  },
  {
    code: 'Surgery-Trauma_and_orthopedics',
    display: 'Surgery-Trauma and orthopedics',
  },
  {
    code: 'Surgery-Vascular',
    display: 'Surgery-Vascular',
  },
  {
    code: 'Surgical_oncology',
    display: 'Surgical oncology',
  },
  {
    code: 'Surgical-Accident_&_emergency',
    display: 'Surgical-Accident & emergency',
  },
  {
    code: 'Thoracic_medicine',
    display: 'Thoracic medicine',
  },
  {
    code: 'Toxicology',
    display: 'Toxicology',
  },
  {
    code: 'Tropical_medicine',
    display: 'Tropical medicine',
  },
  {
    code: 'Urological_oncology',
    display: 'Urological oncology',
  },
  {
    code: 'Urology',
    display: 'Urology',
  },
  {
    code: 'Medical_specialty--OTHER--NOT_LISTED',
    display: 'Medical specialty--OTHER--NOT LISTED',
  },
  {
    code: 'Surgical_specialty--OTHER-NOT_LISTED',
    display: 'Surgical specialty--OTHER-NOT LISTED',
  },
]
