export default [
  {
    code: 'Audiology',
    display: 'Audiology',
  },
  {
    code: 'Blood Gases',
    display: 'Blood Gases',
  },
  {
    code: 'Blood Bank',
    display: 'Blood Bank',
  },
  {
    code: 'Cytogenetics',
    display: 'Cytogenetics',
  },
  {
    code: 'Chemistry',
    display: 'Chemistry',
  },
  {
    code: 'Cytopathology',
    display: 'Cytopathology',
  },
  {
    code: 'CAT Scan',
    display: 'CAT Scan',
  },
  {
    code: 'Cardiac Catheterization',
    display: 'Cardiac Catheterization',
  },
  {
    code: 'Cardiac Ultrasound',
    display: 'Cardiac Ultrasound',
  },
  {
    code: 'Electrocardiac',
    display: 'Electrocardiac (e.g., EKG, EEC, Holter)',
  },
  {
    code: 'Electroneuro',
    display: 'Electroneuro (EEG, EMG,EP,PSG)',
  },
  {
    code: 'Genetics',
    display: 'Genetics',
  },
  {
    code: 'Hematology',
    display: 'Haematology',
  },
  {
    code: 'Bedside ICU Monitoring',
    display: 'Bedside ICU Monitoring',
  },
  {
    code: 'Immunology',
    display: 'Immunology',
  },
  {
    code: 'Laboratory',
    display: 'Laboratory',
  },
  {
    code: 'Microbiology',
    display: 'Microbiology',
  },
  {
    code: 'Mycobacteriology',
    display: 'Mycobacteriology',
  },
  {
    code: 'Mycology',
    display: 'Mycology',
  },
  {
    code: 'Nuclear Magnetic Resonance',
    display: 'Nuclear Magnetic Resonance',
  },
  {
    code: 'Nuclear Medicine Scan',
    display: 'Nuclear Medicine Scan',
  },
  {
    code: 'Nursing Service Measures',
    display: 'Nursing Service Measures',
  },
  {
    code: 'Outside Lab',
    display: 'Outside Lab',
  },
  {
    code: 'Occupational Therapy',
    display: 'Occupational Therapy',
  },
  {
    code: 'Other',
    display: 'Other',
  },
  {
    code: 'OB Ultrasound',
    display: 'OB Ultrasound',
  },
  {
    code: 'Pulmonary Function',
    display: 'Pulmonary Function',
  },
  {
    code: 'Pharmacy',
    display: 'Pharmacy',
  },
  {
    code: 'Physician',
    display: 'Physician (Hx. Dx, admission note, etc.)',
  },
  {
    code: 'Physical Therapy',
    display: 'Physical Therapy',
  },
  {
    code: 'Radiology',
    display: 'Radiology',
  },
  {
    code: 'Respiratory Care (therapy)',
    display: 'Respiratory Care (therapy)',
  },
  {
    code: 'Radiation Therapy',
    display: 'Radiation Therapy',
  },
  {
    code: 'Radiology Ultrasound',
    display: 'Radiology Ultrasound',
  },
  {
    code: 'Radiograph',
    display: 'Radiograph',
  },
  {
    code: 'Surgical Pathology',
    display: 'Surgical Pathology',
  },
  {
    code: 'Serology',
    display: 'Serology',
  },
  {
    code: 'Toxicology',
    display: 'Toxicology',
  },
  {
    code: 'Virology',
    display: 'Virology',
  },
  {
    code: 'Vascular Ultrasound',
    display: 'Vascular Ultrasound',
  },
  {
    code: 'Cineradiograph',
    display: 'Cineradiograph',
  },
]
